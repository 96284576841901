
import feathersClient, {makeServicePlugin, BaseModel} from '../../api/feathers';

class User extends BaseModel
{
    static modelName = 'User';
    static instanceDefaults()
    {
        return {
            relations: []
        };
    }
}

const servicePath = feathersClient.getServicePath('users');
const servicePlugin = makeServicePlugin({Model: User, service: feathersClient.service(servicePath), servicePath});

feathersClient.service(servicePath).hooks(
{
    before:
    {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after:
    {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error:
    {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

export default servicePlugin;
