
import app from '../api/feathers';
import Video from 'twilio-video';

let accessToken = '';

async function initialize(userId, roomId)
{
    const result = await app.getService('rtc').get('token', {query: {user: userId, room: roomId}}).catch(err => {});
    const token = result.token || '';
    accessToken = token;
}

function attachTrack(track, container)
{
    container.appendChild(track.attach());
    // console.log('Twilio: Attaching track:', track, container);
};

function attachTracks(tracks, container)
{
    tracks.forEach(track =>
    {
        attachTrack(track, container);
    });
};

function detachTrack(track)
{
    // console.log('Twilio: Detaching...');
    track.detach().forEach(element =>
    {
        element.remove();
        // console.log('Twilio: Detaching track:', track, element);
    });
};

function getCapabilities(track, ptz)
{
    let result = false;
    const mt = track.mediaStreamTrack;
    if (mt)
    {
        const capabilities = mt.getCapabilities && mt.getCapabilities();
        const settings = mt.getSettings && mt.getSettings();
        if (capabilities && settings)
        {
            if (ptz in settings)    result = capabilities[ptz];
        }
    }
    return result;
}

function applyConstraints(track, constraints)
{
    const mt = track.mediaStreamTrack;
    if (mt)
    {
        mt.applyConstraints(constraints).catch(err => false);
    }
}

function muteTrack(track)
{
    track.disable();
}

function unmuteTrack(track)
{
    track.enable();
}

function trackPublished(publication, container)
{
    if (publication.isSubscribed)
    {
        attachTrack(publication.track, container);
    }
    publication.on('subscribed', track =>
    {
        attachTrack(track, container);
    });
    publication.on('unsubscribed', detachTrack);
};

function trackUnpublished(publication)
{
};

function participantConnected(participant, container)
{
    participant.tracks.forEach(publication =>
    {
        trackPublished(publication, container);
    });
    participant.on('trackPublished', publication =>
    {
        trackPublished(publication, container);
    });
    participant.on('trackUnpublished', trackUnpublished);
};

function detachParticipantTracks(participant)
{
    const tracks = getTracks(participant);
    if (tracks) tracks.forEach(detachTrack);
};

function muteParticipantTracks(participant, type)
{
    const tracks = getTracks(participant, type);
    if (tracks) tracks.forEach(muteTrack);
};

function unmuteParticipantTracks(participant, type)
{
    const tracks = getTracks(participant, type);
    if (tracks) tracks.forEach(unmuteTrack);
};

function getMuteState(participant, type)
{
    let state = false;
    const tracks = getTracks(participant, type);
    if (tracks) state = tracks.some(t => t.isEnabled);
};

function getTracks(participant, type = 'tracks')
{
    return Array.from(participant[type].values()).filter(publication =>
    {
        return publication.track;
    }).map(publication =>
    {
        return publication.track;
    });
};

function createLocalTracks(constraints)
{
    return Video.createLocalTracks(constraints);
}

function connect(options)
{
    // console.log('Twilio: Connecting...');
    return Video.connect(accessToken, options);
}

function disconnect(room)
{
    // console.log('Twilio: Disconnecting...');
    if (room) room.disconnect();
}

const twilio = {
    initialize,
    attachTrack,
    attachTracks,
    detachTrack,
    getCapabilities,
    applyConstraints,
    muteTrack,
    unmuteTrack,
    trackPublished,
    trackUnpublished,
    participantConnected,
    detachParticipantTracks,
    muteParticipantTracks,
    unmuteParticipantTracks,
    getMuteState,
    getTracks,
    createLocalTracks,
    connect,
    disconnect
};

export default async ({ Vue }) =>
{
    Vue.prototype.$twilio = twilio;
};

export { twilio };
