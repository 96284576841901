
import app from '../../api/feathers';
import { getUserObject, getTimeObject, loadScript, getTimeZone, convertToTree, getTreeParent } from '../../api/utils';
import Vue from 'vue';
import { SessionStorage } from 'quasar';
import { computeFingerprint } from 'simple-fingerprint';
import { i18n } from 'boot/i18n.js';
import { notifications } from 'boot/notifications.js';

const store = {namespaced: true};

store.state = {
    initialized: false,
    debugValue: '?',
    headerTitle: 'Good Day English',
    headerColor: 'orange',
    toolbarMessage: '',
    backNavigation: '/',
    drawerEnabled: true,
    drawerOpened: false,
    dayIndex: 0,
    serverTime: 0,
    loginTime: Date.now(),
    startTime: Date.now(),
    currentTime: Date.now(),
    offsetTime: 0,
    onlineState: false,
    busyState: false,
    loadingState: false,
    userCredits: 0,
    fingerprint: '',
    subscription: null,
    emolist: ['🙂', '😃', '😆', '😁', '😂', '😍', '😝', '😇', '😎', '🤐', '🙄', '😮', '😱', '🥱', '😤', '😢', '😒', '👍', '❤️', '💥', '🙏', '💪', '🔥', '🌼'],
    favTopics: [],
    gstatic: true,
    teacherMenu: [
        { label: i18n.t('message.title_confirmedbookings'), icon: 'dashboard/icon_menu_confirmedbookings.svg', link: 'teacherBookings', badge: 'bookingsCount' },
        { label: i18n.t('message.title_schedulecancel'), icon: 'dashboard/icon_menu_bookaclass.svg', link: 'teacherSchedule' },
        { label: i18n.t('message.title_comments'), icon: 'dashboard/icon_menu_comments.svg', link: 'teacherComments' },
        { label: i18n.t('message.title_timetable'), icon: 'dashboard/icon_menu_timetable.svg', link: 'teacherTimetable' },
        { label: i18n.t('message.title_alltopics'), icon: 'dashboard/icon_menu_alltopics.svg', link: 'teacherTopics' },
        /* { label: i18n.t('message.title_payments'), icon: 'dashboard/icon_menu_bookaclass.svg', link: 'teacherPayments' }, */
        { label: i18n.t('message.title_mystudents'), icon: 'dashboard/icon_menu_mystudents.svg', link: 'teacherStudents' },
        { label: i18n.t('message.title_myprofile'), icon: 'dashboard/icon_menu_myprofile.svg', link: 'teacherProfile' },
        { label: i18n.t('message.title_chatwithus'), icon: 'dashboard/icon_menu_chat.svg', link: 'https://t.me/GoodDayEnglish' },
        { label: i18n.t('message.title_logout'), icon: 'dashboard/icon_menu_logout.svg', link: 'logout' }
    ],
    studentMenu: [
        { label: i18n.t('message.title_chatwithus'), icon: 'dashboard/icon_menu_chat.svg', link: 'https://m.me/GoodDayEng' },
        { label: i18n.t('message.title_upclasses'), icon: 'dashboard/icon_menu_upcomingclasses.svg', link: 'studentUpClasses', badge: 'bookingsCount' },
        { label: i18n.t('message.title_bookcancel'), icon: 'dashboard/icon_menu_bookaclass.svg', link: 'studentBooking' },
        { label: i18n.t('message.title_alltopics'), icon: 'dashboard/icon_menu_alltopics.svg', link: 'studentTopics' },
        { label: i18n.t('message.title_comments'), icon: 'dashboard/icon_menu_comments.svg', link: 'studentComments' },
        { label: i18n.t('message.title_teachers'), icon: 'dashboard/icon_menu_teachers.svg', link: 'studentTeachers' },
        { label: i18n.t('message.title_payments'), icon: 'dashboard/icon_menu_payments.svg', link: 'studentPayments' },
        { label: i18n.t('message.title_myprofile'), icon: 'dashboard/icon_menu_myprofile.svg', link: 'studentProfile' },
        { label: i18n.t('message.title_logout'), icon: 'dashboard/icon_menu_logout.svg', link: 'logout' }
    ],
    helpMenu: [
        { icon: 'help', title: 'STEP BY STEP GUIDE', link: 'documentViewer', param: {file: 'en_students_guide'}},
        { icon: 'ondemand_video', title: 'HOW TO BOOK A CLASS', link: 'https://www.youtube.com/embed/68ae8AEdX2s?autoplay=1&rel=0&controls=0', param: 'How to book a class'},
        { icon: 'ondemand_video', title: 'HOW TO PREVIEW A CLASS', link: 'https://www.youtube.com/embed/r4LDO3tXZi0?autoplay=1&rel=0&controls=0', param: 'How to preview a class'},
        { icon: 'ondemand_video', title: 'HOW TO CANCEL A CLASS', link: 'https://www.youtube.com/embed/5kAPgKQw-2Y?autoplay=1&rel=0&controls=0', param: 'How to cancel a class'},
        { icon: 'ondemand_video', title: 'LIVE VIDEO CLASS RECORDING', link: 'https://www.youtube.com/embed/ZSOaKGreJ50?autoplay=1&rel=0&controls=0', param: 'Live video class recording'}
    ]
};

store.getters = {
    debugValue: function(state)
    {
        return state.debugValue;
    },
    dayIndex: function(state)
    {
        const idx = state.dayIndex || SessionStorage.getItem('main/dayIndex') || '0';
        return String(idx);
    },
    currentTimeStamp: function(state)
    {
        return state.currentTime;
    },
    serverTimeStamp: function(state)
    {
        const offsetTime = state.offsetTime;
        return (state.currentTime - offsetTime) + state.serverTime;
    },
    runTime: function(state)
    {
        const diff = Date.now() - state.startTime;
        return Math.floor(diff / 1000 % 60);
    },
    currentTime: function(state)
    {
        const offsetTime = state.offsetTime;
        const serverStamp = (state.currentTime - offsetTime) + state.serverTime;
        return `${new Date(serverStamp).toLocaleString()}`;
    },
    currentTimeString: function(state)
    {
        const offsetTime = state.offsetTime;
        const serverStamp = (state.currentTime - offsetTime) + state.serverTime;
        return `${new Date(serverStamp).toLocaleTimeString()}`;
    },
    userCredits: function(state)
    {
        return state.userCredits;
    },
    fingerprint: function(state)
    {
        return state.fingerprint;
    },
    favTopics: function(state)
    {
        return state.favTopics;
    },
    loadingState: function(state)
    {
        return state.loadingState;
    }
};

store.actions = {

    initialize: async function({ commit, state })
    {
    },
    initializeAfterLogin: async function({ commit, dispatch, state })
    {
        if (state.initialized === false)
        {
            commit('initialized', true);

            // init notifications
            const user = getUserObject();
            await notifications.initialize(user, commit).catch(err => false);

            // setup time management
            commit('offsetTime', Date.now());
            let oldTime = Date.now();
            setInterval(() =>
            { // current time
                const sec = getTimeObject().unix();
                const root = Vue.$app.router.app;
                const time = Date.now();
                // const sec = Math.floor(time / 1000);
                if (!(sec % 1800))
                { // emit every 30 minutes
                    root && root.$emit('evt-class-tick');
                }
                if (Math.abs(time - oldTime) > 2000)
                { // emit when time change detected
                    root && root.$emit('evt-time-change');
                }
                commit('currentTime', time);
                oldTime = time;
            }, 1000);

            // calc fingerprint
            const fingerprint = await computeFingerprint().catch(err => '');
            commit('fingerprint', fingerprint);

            // setup credits state watcher
            app.service('payments').on('credits', async data =>
            { // watcher
                if (data && data.credits) commit('userCredits', data.credits);
            });

            // build favtopics
            const rootTopic = await app.service('topics').find({ query: {parent: null} }).then(res => res && res.data && res.data[0] && res.data[0]._id || {}).catch(err => {});
            if (rootTopic)
            {
                const listTopics = await app.service('topics').find({ query: {parent: rootTopic} }).then(res => res && res.data || []).catch(err => []);
                const list = listTopics.map(topic => topic.title);
                commit('favTopics', list);
            }
            // sync server time
            await dispatch('syncServerTime');
        }
    },
    syncServerTime: async function({ commit })
    {
        const timezone = getTimeZone();
        const serverTime = await app.service('system').find({ query: {mode: 'time', timezone: timezone} }).then(res => res && res.serverTime || 0).catch(err => false);
        if (serverTime) commit('serverTime', serverTime);
    },
    syncUserCredits: async function({ commit })
    {
        const cr = await app.service('payments').find({ query: {mode: 'credits'} }).then(res => res && res.credits || 0).catch(err => false);
        commit('userCredits', cr);
    },
    storeSubscription: async function({ commit }, subscription)
    {
        // console.log('[STORE]: Browser subscription stored:', !!subscription);
        commit('subscription', subscription);
    },
    syncSubscription: async function({ commit, state }, user)
    {
        // console.log('[STORE]: Subscription sync:', !!state.subscription, 'User notify setting:', user.notify);
        if (user)
        {
            if (user.notify === true)       await notifications.subscribe();
            else if (user.notify === false) await notifications.unsubscribe();
        }
    },
    reportClientError: async function({ commit }, err)
    {
        if (err)    await app.service('system').find({query: {mode: 'log', type: err.type, message: err.message, url: err.url, line: err.line, stack: err.stack}}).catch(err => false);
    },
    listOfferPackages: async function({ commit }, err)
    {
        const list = await app.service('offers').find({ query: {mode: 'public'} })
        .then(res =>
        {
            const data = res && res.data || [];
            return data.map(item => ({label: item.description, value: item._id}));
        })
        .catch(err =>
        {
            return [];
        });
        return list;
    },
    listTeachers: async function({ commit }, err)
    {
        const list = app.service('users').find({query: {mode: 'relations', role: 'teacher'}})
        .then(res =>
        {
            const data = res && res.data || [];
            return data.map(item => ({label: `${item.firstName} (${item.country})`, value: item._id}));
        })
        .catch(err =>
        {
            return [];
        });
        return list;
    },
    listBookings: async function({ commit }, err)
    { // admin bookings
        const list = app.service('bookings').find({ query: { mode: 'admin', state: { $nin: ['deleted', 'cancelled']}} })
        .then(res =>
        {
            const data = res && res.data || [];
            console.log(data);
            return data.map(item => ({label: `${Vue.filter('formatTimeShort')(item.startAt)}: ${item._topic_.title} (${item._teacher_.firstName} -> ${item._student_.firstName})`, value: item._id}));
        })
        .catch(err =>
        {
            return [];
        });
        return list;
    },
    listTopics: async function({ commit }, err)
    {
        const list = app.service('topics').find({query: {level: {$gte: 1}, $limit: 999, $sort: {code: 1}}})
        .then(res =>
        {
            const data = res && res.data || [];
            return data.map(item => ({label: `${item.code.replace('English/', '')} 🠞 ${item.title}`, value: item._id}));
        })
        .catch(err =>
        {
            return [];
        });
        return list;
    }
};

/*

comment: null
completed: false
confirmed: true
description: ""
difficulty: 0
endAt: "2021-11-09T22:59:59.000Z"
paid: true
rating: 0
seenTimes: (2) [{…}, {…}]
startAt: "2021-11-09T22:30:00.000Z"
state: ""
student: "5e2d9a0b98640f1df0c77057"
teacher: "5e240823eafbd31d10fff594"
title: ""
topic: "5f974df39e01b52a0f33445f"
_id: "618af42cf317513806b9f2bc"
_student_: {_id: '5e2d9a0b98640f1df0c77057', email: 'student@datahorses.com', firstName: 'Timi', avatar: 'user/619aaf6d2e79a77a3c39410ece801d87fcc6b9e9/e80e1d4b6528c0e35e4256b1f78ec339abd9fe1c.jpg', rating: 4}
_teacher_: {_id: '5e240823eafbd31d10fff594', email: 'teacher@datahorses.com', firstName: 'John', rating: 3, avatar: 'user/bb3782021610f9a8be3b7cfa29a0c566a786ded1/351f97e1b8f4e7ef7a2d65d311e7220219245914.jpg'}
_topic_: {_id: '5f974df39e01b52a0f33445f', description: '', icon: 'topic/ea062ae863115d6e781fbaaba6e0311e3823bce3/207435fa87365fc3d363ba094e0

*/


store.mutations = {

    initialized: function(state, value)
    {
        state.initialized = value;
    },
    debugValue: function(state, value)
    {
        state.debugValue = value;
    },
    headerTitle: function(state, value)
    {
        state.headerTitle = value;
    },
    headerColor: function(state, value)
    {
        state.headerColor = value;
    },
    toolbarMessage: function(state, value)
    {
        state.toolbarMessage = value;
    },
    dayIndex: function(state, value)
    {
        state.dayIndex = value;
        SessionStorage.set('main/dayIndex', value);
    },
    serverTime: function(state, value)
    {
        state.offsetTime = Date.now();
        state.serverTime = value;
    },
    loginTime: function(state, value)
    {
        state.loginTime = value;
    },
    currentTime: function(state, value)
    {
        state.currentTime = value;
    },
    offsetTime: function(state, value)
    {
        state.offsetTime = value;
    },
    backNavigation: function(state, value)
    {
        state.backNavigation = value;
    },
    drawerEnabled: function(state, value)
    {
        state.drawerEnabled = value;
    },
    drawerOpened: function(state, value)
    {
        state.drawerOpened = value;
    },
    onlineState: function(state, value)
    {
        state.onlineState = value;
    },
    busyState: function(state, value)
    {
        state.busyState = value;
    },
    loadingState: function(state, value)
    {
        state.loadingState = value;
    },
    userCredits: function(state, value)
    {
        state.userCredits = value || 0;
    },
    fingerprint: function(state, value)
    {
        state.fingerprint = value || '';
    },
    subscription: function(state, value)
    {
        state.subscription = value || '';
    },
    favTopics: function(state, value)
    {
        state.favTopics = value || [];
    },
    gstatic: function(state, value)
    {
        state.gstatic = value;
    }
};

export default store;
