/* eslint-disable indent */

const isSsr = process.env.MODE === 'ssr';

const routes = [];

/* routes.push(
{
    path: '/',
    component: () => import('layouts/BlankLayout.vue'),
    children: [{ path: '', name: 'index', component: () => import('pages/Index.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
}); */

routes.push(
{
    path: '/',
    redirect: { name: 'login' }
});

routes.push(
{
    path: '/login',
    component: () => import('layouts/FrontLayout.vue'),
    children: [{ path: '', name: 'login', component: () => import('pages/global/Login.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});

routes.push(
{
    path: '/logout',
    component: () => import('layouts/BlankLayout.vue'),
    children: [{ path: '', name: 'logout', component: () => import('pages/global/Logout.vue') }],
    meta: { requiresAuth: false, requiresRole: false }
});

routes.push(
{
    path: '/signup',
    component: () => import('layouts/FrontLayout.vue'),
    children: [{ path: '', name: 'signup', component: () => import('pages/global/Signup.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});

routes.push(
{
    path: '/register',
    component: () => import('layouts/FrontLayout.vue'),
    children: [{ path: '', name: 'register', component: () => import('pages/global/SignupConfirm.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});

routes.push(
{
    path: '/password-forgot',
    component: () => import('layouts/FrontLayout.vue'),
    children: [{ path: '', name: 'password_forgot', component: () => import('pages/global/PasswordForgot.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});

routes.push(
{
    path: '/password-reset',
    component: () => import('layouts/FrontLayout.vue'),
    children: [{ path: '', name: 'password_reset', component: () => import('pages/global/PasswordReset.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});

/*
routes.push(
{
    path: '/student-login',
    component: () => import('layouts/FrontLayout.vue'),
    children: [{ path: '', name: 'studentLogin', component: () => import('pages/student/StudentLogin.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});
*/

routes.push(
{
    path: '/student-dashboard',
    component: () => import('layouts/BlankThemeLayout.vue'),
    children: [{ path: '', name: 'studentDashboard', component: () => import('pages/student/StudentDashboard.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-upclasses',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentUpClasses', component: () => import('pages/student/StudentUpClasses.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-book',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentBooking', component: () => import('pages/student/StudentBooking.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

/*
routes.push(
{
    path: '/student-classes',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentClasses', component: () => import('pages/student/StudentClasses.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});
*/

routes.push(
{
    path: '/student-topics',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentTopics', component: () => import('pages/student/StudentTopics.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-instructions',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentInstructions', component: () => import('pages/student/StudentInstructions.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-comments',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentComments', component: () => import('pages/student/StudentComments.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-teachers',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentTeachers', component: () => import('pages/student/StudentTeachers.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-payments',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentPayments', component: () => import('pages/student/StudentPayments.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-credits',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentCredits', component: () => import('pages/student/StudentCredits.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

routes.push(
{
    path: '/student-profile',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'studentProfile', component: () => import('pages/student/StudentProfile.vue') }],
    meta: { requiresAuth: true, requiresRole: 'student' }
});

/*
routes.push(
{
    path: '/teacher-login',
    component: () => import('layouts/FrontLayout.vue'),
    children: [{ path: '', name: 'teacherLogin', component: () => import('pages/teacher/TeacherLogin.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});
*/

routes.push(
{
    path: '/teacher-dashboard',
    component: () => import('layouts/BlankThemeLayout.vue'),
    children: [{ path: '', name: 'teacherDashboard', component: () => import('pages/teacher/TeacherDashboard.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/virtual-class/:id',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'virtualClass', component: () => import('pages/global/VirtualClass.vue') }],
    meta: { requiresAuth: true, requiresRole: false }
});

routes.push(
{
    path: '/document/:file',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'documentViewer', component: () => import('pages/global/DocumentViewer.vue') }],
    meta: { requiresAuth: false, requiresRole: false }
});

/* routes.push(
{
    path: '/user-details/:id',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'userDetails', component: () => import('pages/global/UserDetails.vue') }],
    meta: { requiresAuth: true, requiresRole: false }
}); */

routes.push(
{
    path: '/teacher-bookings',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherBookings', component: () => import('pages/teacher/TeacherBookings.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/timetable',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherTimetable', component: () => import('pages/teacher/TeacherTimetable.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/schedule-cancel',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherSchedule', component: () => import('pages/teacher/TeacherSchedule.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/teacher-comments',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherComments', component: () => import('pages/teacher/TeacherComments.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/teacher-topics',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherTopics', component: () => import('pages/teacher/TeacherTopics.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/teacher-payments',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherPayments', component: () => import('pages/teacher/TeacherPayments.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/teacher-students',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherStudents', component: () => import('pages/teacher/TeacherStudents.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/teacher-profile',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'teacherProfile', component: () => import('pages/teacher/TeacherProfile.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

routes.push(
{
    path: '/payment-success',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'paymentSuccess', component: () => import('pages/global/PaymentSuccess.vue') }],
    meta: { requiresAuth: true, requiresRole: false }
});

routes.push(
{
    path: '/payment-cancel',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'paymentCancel', component: () => import('pages/global/PaymentCancel.vue') }],
    meta: { requiresAuth: true, requiresRole: false }
});

routes.push(
{
    path: '/delete',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'delete', component: () => import('pages/global/DeleteInfo.vue') }],
    meta: { requiresAuth: false, requiresRole: false }
});

routes.push(
{
    path: '/error',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'error', component: () => import('pages/error/Error.vue') }],
    meta: { requiresAuth: false, requiresRole: false }
});

routes.push(
{
    path: '/redirect',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'redirect', component: () => import('pages/global/Redirect.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});

routes.push(
{
    path: '/redirect/:access_token',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'redirectToken', component: () => import('pages/global/Redirect.vue') }],
    meta: { requiresAuth: false, requiresRole: false, redirectAuth: true }
});

routes.push(
{
    path: '/loading',
    component: () => import('layouts/RawLayout.vue'),
    children: [{ path: '', name: 'loading', component: () => import('pages/global/Loading.vue') }],
    meta: { requiresAuth: false, requiresRole: false }
});

routes.push(
{
    path: '/administration',
    component: () => import('layouts/AppLayout.vue'),
    children: [{ path: '', name: 'administration', component: () => import('pages/global/Administration.vue') }],
    meta: { requiresAuth: true, requiresRole: 'teacher' }
});

// Always leave this as last one
// if (!isSsr)
// {
routes.push(
{
    path: '*',
    component: () => import('pages/error/Error404.vue')
});
// }

export default routes;
