import { LocalStorage } from 'quasar';
import { reloadApp } from '../api/utils';

export default ({ app, router, store, Vue }) =>
{
    const auth = {

        isAuth()
        {
            return store.state.auth.user !== null;
        },

        login(name, pass, token)
        {
            return store.dispatch('auth/authenticate', { strategy: 'local', email: name, password: pass, token: token });
        },

        logout(redirect = 'index', query = {})
        {
            return store.dispatch('auth/logout').then(() =>
            {
                router.push({ name: redirect, query: query }, () =>
                { // reset / reload
                    reloadApp();
                });
            }).catch(() =>
            {
                LocalStorage.remove('access-token');
                router.push({ name: redirect, query: query }, () =>
                { // reset / reload
                    reloadApp();
                });
            });
        }
    };

    Vue.prototype.$auth = auth;
};
