
import VueGtag from 'vue-gtag';
import { CONFIG } from '../config/default';

const pixelEvents = [
    'addpaymentinfo', 'addtocart', 'addtowishlist',
    'completeregistration', 'contact', 'customizeproduct',
    'donate',
    'findlocation',
    'initiatecheckout',
    'lead',
    'pageview', 'purchase',
    'schedule', 'search', 'starttrial', 'submitapplication', 'subscribe',
    'viewcontent'
];

const gtagEvents = [
    'add_payment_info',
    'add_to_cart',
    'add_to_wishlist',
    'begin_checkout',
    'checkout_progress',
    'exception',
    'generate_lead',
    'login',
    'page_view',
    'purchase',
    'refund',
    'remove_from_cart',
    'screen_view',
    'search',
    'select_content',
    'set_checkout_option',
    'share',
    'sign_up',
    'timing_complete',
    'view_item',
    'view_item_list',
    'view_promotion',
    'view_search_results'
];


function sendPixelEvent(name, data)
{
    if (process.env.BUILD_MODE === 'production')
    {
        if (window.fbq)
        { // facebook pixel
            if (!pixelEvents.includes(name.toLowerCase())) window.fbq('trackCustom', name, data);
            else window.fbq('track', name, data);
        }
    }
}

function sendGtagEvent(name, data)
{
    if (process.env.BUILD_MODE === 'production')
    {
        if (window.gtag)
        { // google analytics
            window.gtag('event', name, data);
        }
    }
}

const analytics = {
    sendPixelEvent,
    sendGtagEvent
};

export default async ({ Vue, store }) =>
{
    Vue.use(VueGtag, {config: { id: 'G-XFGC8CF0N1' }});
    Vue.prototype.$analytics = analytics;
};

export { analytics };
