
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default function ({ store, ssrContext })
{
    const Router = new VueRouter(
    {
        scrollBehavior: () => ({ x: 0, y: 0 }),
        routes,
        mode: process.env.VUE_ROUTER_MODE,
        base: process.env.VUE_ROUTER_BASE
    });

    Router.beforeEach(async (to, from, next) =>
    {
        store.dispatch('config/updateDateRanges');
        store.commit('main/headerTitle', '');
        store.commit('main/backNavigation', '');
        store.commit('main/drawerEnabled', true);
        store.commit('main/drawerOpened', false);
        const busyState = store.state.main.busyState;
        const currentUser = store.state.auth.user;
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

        let user = currentUser;
        if (!user)
        { // unknown user
            user = await store.dispatch('auth/authenticate').then(result =>
            { // auth ok
                return result.user;
            })
            .catch(() =>
            { // auth failed
                return null;
            });
        }

        // if (busyState)
        // { TODO: add warning
        // }

        if (requiresAuth)
        { // auth required
            if (!user)
            { // auth failed
                next('/');
            }
            else
            { // valid user
                const role = user && user.role || 'unknown';
                const roleCheck = to.matched.some(record => (record.meta.requiresRole === role || record.meta.requiresRole === false));
                if (roleCheck)
                { // valid role - continue to route
                    // console.log(`ROUTE CHANGE: [${from.path}] -> [${to.path}] (user: ${user && user.email}, role: ${user && user.role})`);
                    await store.dispatch('main/initializeAfterLogin');
                    next();
                }
                else
                { // role check failed
                    next('/'); // TODO: force logout
                }
            }
        }
        else
        { // no auth required
            const redirectAuth = to.matched.some(record => record.meta.redirectAuth);
            if (user && redirectAuth)
            {
                const role = user && user.role || 'unknown';
                if (to.name === 'signup')
                {
                    const q = to.query;
                    q.redirect = 'signup';
                    next({name: 'logout', query: q});
                }
                else if (role === 'teacher')         next('/teacher-dashboard');
                else if (role === 'student')    next('/student-dashboard');
                else if (role === 'admin')      next('/admin-dashboard');
            }
            // console.log(`[ROUTE]: CHANGE [${from.path}] -> [${to.path}]`);
            next();
        }
    });

    Router.afterEach(async (to, from) =>
    {
        const user = store.state.auth.user;
        if (user && to.path !== '/redirect/')
        {
            await store.dispatch('main/syncSubscription', user);
        }
    });

    return Router;
}
