<script>

import Vue from 'vue';
import { ref, computed, onMounted } from '@vue/composition-api';
import errorDialog from 'components/errorDialog';

export default
{
    name: 'App',
    meta:
    {
        title: 'Good Day English'
    },
    setup(props, context)
    {
        const { $store } = context.root;
        const { $q } = context.parent;

        const isOnline = ref((navigator.onLine));
        const updateState = function()
        {
            isOnline.value = navigator.onLine;
        };
        window.addEventListener('online', updateState);
        window.addEventListener('offline', updateState);

        /*
        const currentLog = console.log;
        const currentError = console.error;
        const logData = ref([]);

        console.log = msg => {
            if (msg !== undefined) logData.value.push(`[LOG]: ${msg}`);
            currentLog.apply(null, arguments);
        };
        console.error = msg => {
            if (msg !== undefined) logData.value.push(`[ERR]: ${msg}`);
            currentError.apply(null, arguments);
        };

        window.onerror = function (err, url, line)
        {
            logData.value.push(`[WIN]: ${err} / ${url} / ${line}`);
        };
        */

        onMounted(() =>
        {
            // $q.addressbarColor.set('#32A690');

            window.onerror = function (errorMsg, url, lineNumber, column, errorObj)
            {
                if (errorMsg !== 'ResizeObserver loop limit exceeded')
                {
                    // console.log('[ERROR]: Client: ', errorMsg);
                    $store.dispatch('main/reportClientError', {type: 'client', message: errorMsg, url: url, line: lineNumber});
                }
                return false;
            };

            Vue.config.errorHandler = function (err, vm, info)
            {
                const tag = vm && vm.$vnode && vm.$vnode.tag || '?';
                // console.log('[ERROR]: VUE: ', err.message);
                $store.dispatch('main/reportClientError', {type: 'client', message: err.message, url: tag, line: '', stack: err.stack});
                return false;
            };

            context.root.$on('evt-error-dialog', evt =>
            { // network error detected
                $q.dialog({ component: errorDialog, title: evt.title, text: evt.text, details: evt.details, action: evt.action })
                .onOk(async teacher =>
                {
                    if (evt.onOk)   evt.onOk();
                })
                .onDismiss(() =>
                {
                    if (evt.onDismiss)   evt.onDismiss();
                });
            });

            context.root.$on('evt-time-change', async evt =>
            { // client time change detected
                await $store.dispatch('main/syncServerTime');
            });
        });

        return { isOnline };
    }
};
</script>

<template>
    <div id="q-app">
        <transition appear enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp" mode="out-in">
        <q-bar v-if="!isOnline" class="fixed-top bg-red-8 text-white" style="z-index: 9999;">
            <q-icon name="warning" /><div class="text-no-wrap">Warning! Connection lost...</div>
        </q-bar>
        </transition>
        <router-view />
    </div>
</template>

