
import Vue from 'vue';
import feathersApp  from '../api/feathers';
import CompositionApi from '@vue/composition-api';
import o9n from 'o9n';
import { loadScript } from '../api/utils';

export default async ({ app, router, store, Vue }) =>
{
    Vue.$app = app;
    Vue.use(CompositionApi);
    await o9n.orientation.lock('natural').then(res => true).catch(err => false);
    const rx = await feathersApp.authenticate().then(res => true).catch(err => false);
    await store.dispatch('main/initialize');
    console.log('%cGOOD DAY ENGLISH', 'font-weight:bold;color:#fff;background:#da7417;padding:5px 12px;border-radius:8px');
};
