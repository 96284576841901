
import Vue from 'vue';
import app from '../api/feathers';
import { loadScript, detectNative } from '../api/utils';
import { CONFIG } from '../config/default';
// const OneSignal = require('onesignal-node');

let pushEnabled = false;

async function initialize(user, storeCommit)
{
    // const cordova = window.cordova.OneSignal;
    // const iosSettings = {};
    // iosSettings.kOSSettingsKeyAutoPrompt = false;
    // iosSettings.kOSSettingsKeyInAppLaunchURL = false;
    // cordova.startInit(CONFIG.notifications.appId).iosSettings(iosSettings).endInit();

    const isNative = detectNative();
    // if (isNative)   return;
    if (!user)      return;
    await loadScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js').catch(err => false);
    const OneSignal = window.OneSignal || [];
    const config = {
        appId: CONFIG.onesignal.appId,
        safari_web_id: CONFIG.onesignal.safariWebId,
        persistNotification: true,
        autoResubscribe: true,
        notifyButton: {enable: true}
    };
    OneSignal.push(function()
    { // init onesignal
        OneSignal.init(config);
        // OneSignal.log.setLevel('trace');
        // if (!OneSignal.isPushNotificationsSupported())  return;
        OneSignal.isPushNotificationsEnabled(isEnabled => { pushEnabled = isEnabled; });
        OneSignal.showNativePrompt();
        OneSignal.setExternalUserId(String(user._id), user.authVerify);
        OneSignal.getUserId().then(userId =>
        {
            // console.log('OneSignal User ID:', userId);
        }).catch(error =>
        {
            // storeCommit('debugValue', error.message);
        });

        /*
        OneSignal.getExternalUserId().then(externalUserId =>
        {
            console.log('OneSignal ExternalUserId: ', externalUserId);
        });
        OneSignal.on('subscriptionChange', isSubscribed =>
        {
            console.log('OneSignal Subscription change:', isSubscribed);
        });
        OneSignal.on('permissionPromptDisplay', () =>
        {
            console.log('OneSignal: The prompt displayed');
        });
        OneSignal.on('notificationDisplay', event =>
        {
            console.warn('OneSignal: notification displayed:', event);
        });
        OneSignal.on('notificationDismiss', event =>
        {
            console.warn('OneSignal notification dismissed:', event);
        });
        */
    });
}

async function subscribe()
{
}

async function unsubscribe()
{
}

const notifications = {
    initialize,
    subscribe,
    unsubscribe
};

export default async ({ Vue, store }) =>
{
    Vue.prototype.$notifications = notifications;
};

export { notifications };
