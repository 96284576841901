
import { CONFIG } from '../config/default';
import Vue from 'vue';
import feathersApplication from '@feathersjs/feathers';
import authentication from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import feathersVuex from 'feathers-vuex';
import { iff, discard } from 'feathers-hooks-common';

let timeoutCounter = 0;

// create application
const app = feathersApplication();

// websocket setup
const socket = io(CONFIG.backend.endpoint, { path: CONFIG.backend.apiPath + '/ws', transports: ['websocket']});
app.configure(socketio(socket, { timeout: 20000 }));

socket.io.on('connect_error', function(err)
{
    console.log('Error connecting to server...');
});

// get service helper
// app.getServicePath = name => CONFIG.backend.apiPath + '/' + name;
// app.getService = name => app.service(CONFIG.backend.apiPath + '/' + name);
app.getServicePath = name => name;
app.getService = name => app.service(name);

// custom auth client
class CustomAuthenticationClient extends authentication.AuthenticationClient
{
    /*
    async getAccessToken()
    {
        return super.getAccessToken().then(token =>
        {
            console.log('AUTH CLIENT: getAccessToken:', token);
            return token;
        });
    }

    setAccessToken(token)
    {
        console.log('AUTH CLIENT: setAccessToken:', token);
        return super.setAccessToken(token);
    }
    */
};

// auth setup
const authConfig = {
    storage: window.localStorage,
    storageKey: 'access-token',
    path: /* CONFIG.backend.apiPath + */ '/authentication',
    Authentication: CustomAuthenticationClient
};
app.configure(authentication(authConfig));

const successHandler = function(ctx)
{
    timeoutCounter = 0;
    if (ctx.result && ctx.result.serverTime)
    { // sync server time
        const store = Vue.$app && Vue.$app.store;
        if (store) store.commit('main/serverTime', ctx.result.serverTime);
    }
};

const errorHandler = function(ctx)
{
    const store = Vue.$app.store;
    const err = ctx.error || {};
    const app = ctx.app;
    const type = err.className || '';
    // console.log('[ERROR]:', type, err);

    let report = true;
    if (type === 'timeout')
    { // network error
        timeoutCounter += 1;
        if (timeoutCounter > 5)
        {
            app.emit('global-error-network', ctx);
            timeoutCounter = 0;
        }
        report = false;
    }
    else if (type === 'not-authenticated')
    {
        app.emit('global-error-session', ctx);
        report = false;
    }
    else if (type === 'bad-request')
    {
        report = false;
    }

    // report error
    if (report)
    {
        if (store)  store.dispatch('main/reportClientError', {type: 'handler', message: err.stack, url: type, line: err.code});
        // app.service('system').find({query: {mode: 'log', message: err.stack, url: type, line: err.code}}).catch(err => false);
    }
};

// global hooks
app.hooks(
{
    before:
    {
        all:
        [
            iff(ctx => ['create', 'update', 'patch'].includes(ctx.method), discard('__id', '__isTemp'))
        ]
    },
    after:
    {
        all:
        [
            successHandler
            /* ctx =>
            {
                if (ctx.result && ctx.result.serverTime)
                { // sync server time
                    const store = Vue.$app && Vue.$app.store;
                    if (store) store.commit('main/serverTime', ctx.result.serverTime);
                }
            } */
        ]
    },
    error:
    {
        all:
        [
            errorHandler
        ]
    }
});

app.on('global-error-network', ctx =>
{
    const root = Vue.$app.router.app;
    root && root.$emit('evt-error-dialog', {title: 'Network Error', text: 'A network error occurred. Please make sure your internet connections is working and try again...', details: ctx && ctx.error && ctx.error.stack || '', action: 'Retry', onOk: () => { location.reload(); }});
});

app.on('global-error-session', () =>
{
    const root = Vue.$app.router.app;
    const store = Vue.$app.store;
    const runtime = store ? store.getters['main/runTime'] : 0;
    const requiresAuth = Vue.$app.router.currentRoute.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth)
    {
        if (runtime < 10)
        { // redirect
            Vue.$app.router.replace({name: 'index'}).catch(err => false);
        }
        else
        { // show dialog
            root && root.$emit('evt-error-dialog', {title: 'Session expired', text: 'Please sign-in to renew your session...', action: 'Sign In', onOk: () => { root && root.$auth.logout(); }});
        };
    }
});

Vue.prototype.$feathers = app;
export default app;

// vuex setup
const vuexConfig = {
    serverAlias: 'api',
    idField: '_id',
    whitelist: ['$regex', '$options'],
    enableEvents: true
};
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(app, vuexConfig);
export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex };
