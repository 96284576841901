
const ENUM =
{
    USERTYPE:
    {
        STUDENT:    'student',
        TEACHER:    'teacher'
    },

    SCHEDULETYPE:
    { // IN DB
        NORMAL: 'n',
        ONLINE: 'o'
    },

    SCHEDULESLOT:
    { // IN PLANNING
        EXPIRED:    'expired',
        BLOCKED:    'blocked',
        PLANNED:    'planned',
        EMPTY:      'empty',
        LIVE:       'live',
        ONLINE:     'online',
        AVAILABLE:  'available',
        BOOKED:     'booked'
    },

    TRACKSTATE:
    {
        ERROR:      'error',
        IDLE:       'idle',
        PREVIEW:    'preview',
        CONNECT:    'connect',
        WAIT:       'wait',
        ACTIVE:     'active',
        END:        'end'
    },

    RTCLAYOUT:
    {
        LOCAL_FIRST: 'lt-rb',
        REMOTE_FIRST: 'rt-lb',
        LOCAL_ONLY: 'lo',
        REMOTE_ONLY: 'ro'
    },

    ANALYTICS_PIXEL:
    {
        SCHEDULE:       'Schedule',
        REGISTRATION:   'CompleteRegistration',
        INIT_CHECKOUT:  'InitiateCheckout',
        PURCHASE:       'Purchase',
        DOWNLOAD_APP:   'download_app'
    },

    ANALYTICS_GTAG:
    {
        SCHEDULE:       'select_content',
        REGISTRATION:   'sign_up',
        INIT_CHECKOUT:  'begin_checkout',
        PURCHASE:       'purchase',
        DOWNLOAD_APP:   'download_app'
    },

    CURRENCY_SYMBOL:
    {
        EUR:            '€',
        CNY:            '¥'
    }

};

export default ENUM;
