import { render, staticRenderFns } from "./App.vue?vue&type=template&id=4505e7a3&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBar from 'quasar/src/components/bar/QBar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBar,QIcon});
