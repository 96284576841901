
import feathersClient, {makeServicePlugin, BaseModel} from '../../api/feathers';
class Topic extends BaseModel
{
    static modelName = 'Topic';
    static instanceDefaults()
    {
        return {
        };
    }
}

const servicePath = feathersClient.getServicePath('topics');
const servicePlugin = makeServicePlugin({Model: Topic, service: feathersClient.service(servicePath), servicePath});

// Optionally add service-level hooks, here:
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

  export default servicePlugin;
