
import app from '../api/feathers';
// import {loadStripe} from '@stripe/stripe-js';
import { CONFIG } from '../config/default';

let library = '';
let stripe = null;

async function initialize(userId, roomId)
{
    library = CONFIG.payments.library;
    if (library === 'stripe')
    {
        const {loadStripe} = await import('@stripe/stripe-js');
        const key = CONFIG.payments.stripe.publicKey;
        stripe = await loadStripe(key);
    }
}

async function openCheckoutPage(sessionId)
{
    const redirect = stripe.redirectToCheckout({ sessionId: sessionId });
    return redirect;
};

const payments = {
    initialize,
    openCheckoutPage
};

export default async ({ Vue }) =>
{
    // await payments.initialize();
};

export { payments };
