
import TimePast from 'time_past';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getConfigValue, getCurrencySymbol } from '../api/utils';
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
import { format } from 'quasar';

dayjs.extend(relativeTime);

export default ({ store, Vue }) =>
{
    const storagePath = getConfigValue('backend', 'storagePath');

    Vue.filter('capitalize', function(value)
    {
        if (!value) return '';
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    });

    Vue.filter('uppercase', function(value)
    {
        if (!value) return '';
        return value.toUpperCase();
    });

    Vue.filter('cutString', function(text, length)
    {
        if (!text) text = '';
        if (isNaN(length)) length = text.length;
        if (text.length <= length || text.length <= length) return text;
        else return String(text).substring(0, length) + '...';
    });

    Vue.filter('formatIsoDate', function(date)
    {
        const dt = new Date(date);
        if (dt.getTime()) return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        else return '-';
    });

    Vue.filter('formatTimeAgo', function(date)
    {
        return date ? TimePast.inWords(date) : '';
    });

    Vue.filter('formatTimeNext', function(date, stamp)
    {
        return date ? dayjs(stamp).to(date, true).toUpperCase() : '';
    });

    Vue.filter('formatTimeShort', function(date)
    {
        return date ? dayjs(date).format('HH:mm') : '';
    });

    Vue.filter('formatUnixShort', function(date)
    {
        return date ? dayjs(date * 1000).format('HH:mm') : '--:--';
    });

    Vue.filter('formatDateShort', function(date)
    {
        return date ? dayjs(date).format('dddd, MMMM D') : '';
    });

    Vue.filter('formatDateLong', function(date)
    {
        return date ? dayjs(date).format('dddd DD.MM.YYYY, HH:mm') : '';
    });

    Vue.filter('formatDate', function(date, format = 'DD.MM.YYYY')
    {
        return date ? dayjs(date).format(format) : '';
    });

    Vue.filter('formatVersion', function(date)
    {
        return date ? dayjs(date).format('YYMMDD.HHmm') : '';
    });

    Vue.filter('isExpired', function(date)
    {
        let res = '';
        if (date)
        {
            if (dayjs().unix() > dayjs(date).unix())    res = '(EXPIRED)';
        }
        return res;
    });

    Vue.filter('avatarLink', function(user)
    {
        return user && user.avatar ? `${storagePath}/${user.avatar}` : 'images/avatar.jpg';
    });

    Vue.filter('iconLink', function(record)
    {
        return record && record.icon ? `${storagePath}/${record.icon}` : 'images/flag_uk_3d_xs.png';
    });

    Vue.filter('topicLink', function(path)
    {
        return  path ? `${storagePath}/${path}` : 'images/flag_uk_3d_xs.png';
    });

    Vue.filter('slideLink', function(path)
    {
        return path ? `${storagePath}/${path}` : 'images/flag_uk_3d_xs.png';
    });

    Vue.filter('documentLink', function(file)
    {
        return file ? `${storagePath}/document/${file}` : '';
    });

    Vue.filter('imagesLink', function(path)
    {
        return path ? `/images/${path}` : '';
    });


    Vue.filter('fullName', function(user)
    {
        return user ? `${user.firstName} ${user.lastName}` : '';
    });

    Vue.filter('userName', function(user)
    {
        return user ? `${user.firstName}` : '';
    });

    Vue.filter('formatBoolean', function(state)
    {
        return state ? 'Yes' : 'No';
    });

    Vue.filter('secondsToMins', function(value)
    {
        return value ? (value / 60).toFixed(1) + ' min.' : '(N/A)';
    });

    Vue.filter('formatMonthName', function(month)
    {
        const m = Number(month);
        return m > 0 ? months[m - 1] || '-' : '';
    });

    Vue.filter('currencySymbol', function(currency)
    {
        return getCurrencySymbol(currency) || '';
    });
};
