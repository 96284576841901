
import feathersClient, {makeServicePlugin, BaseModel} from '../../api/feathers';
class Booking extends BaseModel
{
    static modelName = 'Booking';
    static instanceDefaults()
    {
        return {
            _topic_: {},
            _teacher_: {},
            _student_: {}
        };
    }
}

const servicePath = feathersClient.getServicePath('bookings');
const servicePlugin = makeServicePlugin({Model: Booking, service: feathersClient.service(servicePath), servicePath});

// Optionally add service-level hooks, here:
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

  export default servicePlugin;
