import Vue from 'vue';
import Vuex from 'vuex';
import feathersVuex from 'feathers-vuex';
// import feathersClient from '../api/feathers';
import feathersClient, { makeAuthPlugin, FeathersVuex } from '../api/feathers';

import main from './main';
import config from './config';

/*
const { makeAuthPlugin, FeathersVuex } = feathersVuex(feathersClient, {
    serverAlias: 'api',
    idField: '_id',     // The field in each record that will contain the id
    autoRemove: false,  // automatically remove records missing from responses (only use with feathers-rest)
    nameStyle: 'short', // Determines the source of the module name. 'short' or 'path'
    enableEvents: true  // Set to false to explicitly disable socket event handlers.
});
*/

Vue.use(FeathersVuex);
Vue.use(Vuex);

Vue.config.devtools = true;

const authPlugin = makeAuthPlugin({ userService: 'users' });
const requireModule = require.context('./services', false, /.js$/);
const servicePlugins = requireModule.keys().map(modulePath => requireModule(modulePath).default);

export default function (/* { ssrContext } */)
{
    const Store = new Vuex.Store({
        plugins: [...servicePlugins, authPlugin],
        modules: { main, config }
        /* strict: process.env.DEV */
    });

    if (process.env.DEV)
    {
        window.$store = Store;
    }

    return Store;
};
