
import { CONFIG } from '../config/default';
import Vue from 'vue';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import ENUM from '../api/enums';

import { arrayToTree } from 'performant-array-to-tree';
import { scroll, Platform } from 'quasar';
import NoSleep from 'nosleep.js';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

const { getScrollTarget, setScrollPosition } = scroll;
const noSleep = new NoSleep();

const getConfigValue = function(group, key, defValue = '')
{
    return CONFIG[group] && CONFIG[group][key] || defValue;
};

const getUserObject = function()
{
    let user = null;
    const store = Vue.$app && Vue.$app.store;
    if (store)  user = store.getters['auth/user'];
    return user;
};

const getTimeObject = function(time)
{
    if (!time)
    {
        const store = Vue.$app && Vue.$app.store;
        if (store)  time = store.getters['main/serverTimeStamp'];
    }
    const current = dayjs(time);
    return current;
};

const generateDays = function()
{
    const limit = CONFIG.booking.daylimit || 7;
    const days = [];
    let current = getTimeObject();
    for (let i = 0; i < limit; i++)
    {
        const day = {};
        day.key = String(i);
        day.date = current.startOf('day');
        day.label1 = current.format('dddd');
        day.label2 = current.format('DD.MM.');
        day.label3 = current.format('DD.MM.YYYY');
        day.label4 = current.format('YYYY');
        day.label5 = current.format('ddd');
        day.bookings = [];
        days.push(day);
        current = current.add(1, 'day');
    }
    return days;
};

const generateTimes = function()
{
    const lessonLimit = CONFIG.booking.session || 30;
    const times = [];
    let current = getTimeObject();
    current = current.startOf('day');
    let minute = 0;
    while (minute < 1440)
    {
        times.push({key: minute, state: 'empty', label: current.format('HH:mm')});
        current = current.add(lessonLimit, 'minutes');
        minute += lessonLimit;
    }
    return times;
};

const generateOnlineTimes = function()
{
    const lessonLimit = CONFIG.booking.session || 30;
    const lessionSecs = lessonLimit * 60;
    const onlineHours = CONFIG.booking.onlineHours || 4;
    const onlineMins = onlineHours * 60;
    const currentTime = getTimeObject();
    const times = [];
    const unix = Math.ceil(currentTime.unix() / lessionSecs) * lessionSecs;
    const startTime = dayjs(unix * 1000);
    let minute = 0;
    while (minute < onlineMins)
    {
        minute += lessonLimit;
        const endTime = startTime.add(minute, 'minutes');
        const hours = (((minute / 60) * 10) << 0) * 0.1;
        const label = `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
        const info =  `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
        times.push({key: minute, label: label, info: info, startAt: startTime.toDate(), endAt: endTime.toDate()});
    }
    return times;
};


const convertToTree = function(list, id = '_id', parentId = 'parent')
{
    return arrayToTree(list, { id: id, parentId: parentId, dataField: null });
};

const getTreeParent = function(root, id)
{
    let node;
    root.some(n =>
    {
        if (n._id === id)   { node = n; return node; }
        if (n.children)     { node = getTreeParent(n.children, id); return node; }
        return null;
    });
    return node || null;
};

const classifyBooking = function(timediff)
{
    let result;
    if (timediff < -1800)         result = 'P'; // PAST
    else if (timediff < 0)        result = 'A'; // ACTUAL / LIVE
    else if (timediff < 43200)    result = 'N'; // NEAR (12 hrs)
    else                          result = 'F'; // FUTURE
    return result;
};

const classifyBookings = function(bookings, timestamp)
{
    if (!timestamp)  timestamp = Date.now();
    for (const booking of bookings)
    {
        const timestart = new Date(booking.startAt).getTime();
        const timediff = Math.floor((timestart - timestamp) / 1000);
        const result = classifyBooking(timediff);
        booking.$timeCategory = result;
        booking.$timeText = new Date(timediff * 1000).toISOString().substr(11, 8);
        booking.$timeToStart = timediff;
    }
    return bookings;
};

const scrollToElement = function(el, duration = 300)
{
    const target = getScrollTarget(el);
    const offset = el.offsetTop;
    setScrollPosition(target, offset, duration);
};

const enableNoSleep = function()
{
    try
    {
        noSleep.enable();
    }
    catch (e)
    {};
};

const disableNoSleep = function()
{
    try
    {
        noSleep.disable();
    }
    catch (e)
    {};
};

const detectPwa = function()
{
    return  ((window.navigator.standalone === true) || (window.matchMedia('(display-mode: standalone)').matches));
};

const detectNative = function()
{
    return (Platform.is.cordova || Platform.is.capacitor);
};

const loadScript = function(src)
{
    return new Promise(function(resolve, reject)
    {
        const s = document.createElement('script');
        let r = false;
        s.type = 'text/javascript';
        s.src = src;
        s.async = true;
        s.onerror = function(err) { reject(err); };
        s.onload = s.onreadystatechange = function()
        {
            if (!r && (!this.readyState || this.readyState === 'complete'))
            {
                r = true;
                resolve(s);
            }
        };
        document.head.appendChild(s);
      });
};

const reloadApp = function()
{
    try
    {
        const url = window.location.href;
        const u = new URL(url);
        u.searchParams.set('t', Date.now());
        window.location.href = u.href;
    }
    catch (e)
    {
        window.location.reload();
    }
};

const sleep = function(ms)
{
    return new Promise(resolve => setTimeout(resolve, ms));
};

const getTimeZone = function()
{
    return dayjs.tz.guess();
};

const getUserCurrency = function(user)
{
    let currency = user && user.currency || '';
    if (!currency)
    { // autodetect
        let tz = user && user.timezone || '';
        if (!tz)    tz = getTimeZone();
        tz = tz.toLowerCase();
        if (tz.includes('asia'))    currency = 'Cny';
        else                        currency = 'Eur';
    }
    return currency;
};

const getCurrencySymbol = function(currency)
{
    let result = '';
    const t = currency.toLowerCase();
    if (t === 'eur')        result = ENUM.CURRENCY_SYMBOL.EUR;
    else if (t === 'cny')   result = ENUM.CURRENCY_SYMBOL.CNY;
    return result;
};

const getUserCurrencySymbol = function(user)
{
    const c = getUserCurrency(user);
    const s = getCurrencySymbol(c);
    console.log('CURRENCY TEST:', c, s);
    return s;
};

export { getConfigValue, getUserObject, getTimeObject, generateDays, generateTimes, generateOnlineTimes, convertToTree, getTreeParent, classifyBooking, classifyBookings, scrollToElement, enableNoSleep, disableNoSleep, detectPwa, detectNative, loadScript, reloadApp, sleep, getTimeZone, getUserCurrency, getCurrencySymbol, getUserCurrencySymbol };
