
import app from '../api/feathers';
import { CONFIG } from '../config/default';
import { Platform } from 'quasar';
import { sleep } from '../api/utils';

let platform = 'web';

function globalListDirectory(path)
{
    return new Promise(function(resolve, reject)
    {
        if (!window.resolveLocalFileSystemURL)  return reject();
        window.resolveLocalFileSystemURL(path,
        fileSystem =>
        {
            const reader = fileSystem.createReader();
            reader.readEntries(entries => { resolve(entries.map(entry => entry.name)); }, err => { reject(err); });
        },
        err =>
        {
            reject(err);
        });
    });
}

function globalConfirmDialog(title, message, buttons)
{
    return new Promise(resolve =>
    {
        navigator.notification.confirm(message, buttonIndex => { resolve(buttonIndex); }, title, buttons);
    });
}

async function androidInit()
{
    for (;;)
    { // camera permission loop
        const camera = await androidRequestPermission('camera');
        if (camera) break;
        await sleep(100);
        const btn = await globalConfirmDialog('Warning', 'This application requires camera access. Are you sure to continue without camera access?', ['No', 'Yes']);
        if (btn === 2) break;
        await sleep(100);
    }

    // let files  = await globalListDirectory(cordova.file.applicationDirectory + 'www/');
    // alert(files.toString());
}

async function androidRequestPermission(type)
{
    return new Promise(function(resolve, reject)
    {
        const permissions = cordova.plugins.permissions;
        if (!permissions)   return reject();
        if (type === 'camera')
        { // camera permission
            permissions.hasPermission(permissions.CAMERA, status =>
            {
                if (status.hasPermission)
                { // all ok
                    return resolve(true);
                }
                else
                { // no permission
                    permissions.requestPermission(permissions.CAMERA,
                    status =>
                    { // permission granted
                        resolve(status.hasPermission);
                    },
                    error =>
                    { // permission not granted
                        resolve(false);
                    });
                }
            });
        }
        else
        { // unknown permission
            reject();
        }
    });
}

async function iosInit()
{
}

async function initialize()
{
    /* eslint-disable-next-line no-undef */
    if (device)  platform = device.platform;
    platform = platform || 'web';
    platform = platform.toLowerCase();
    console.log(`Platform: [${platform}]`);
    if (platform === 'android')     await androidInit();
    else if (platform === 'ios')    await iosInit();
}

const platforms = {
    initialize
};

export default async ({ Vue }) =>
{
    document.addEventListener('deviceready', platforms.initialize, false);
};

export { platforms };


