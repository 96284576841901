
const CONFIG = {};

if (process.env.BUILD_MODE === 'development')
{ // development
    CONFIG.backend = {
        endpoint: 'https://dev.gooddayenglish.com',
        apiPath: '/api',
        storagePath: 'https://dev.gooddayenglish.com/data',
        shortCode: 'DEV'
    };


    CONFIG.onesignal = {
        appId: '418d5019-e645-4576-8a99-1ef416d0d2d6',
        restApiKey: 'MTJjOGZmODgtNjU5Zi00YjZmLTk2NWQtZDdkZDk3YTY1NTUy',
        safariWebId : 'web.onesignal.auto.040fbea3-5bf4-4f81-a6ad-042d48246d00'
    };


    CONFIG.notifications = {
        publicServerKey: 'BCVTvyc4VXc5fTsnohqBEfjk5T17c6B1K9-uviR9qrFubYHq5EuCN60fz56I_ydwm4OETOJHyczgE-haM84ydsg',
        appId: '4490fc3c-1dd7-4ad6-94ea-ddbc7d3760da'
    };

    CONFIG.captcha = {
        siteKey: '6LdZvBsaAAAAAJ5dwRcQqNbms3KmBWdITJGTtCfq'
    };

    CONFIG.payments = {
        library: 'stripe',
        stripe:
        {
            publicKey: 'pk_test_51IhhkqGGKEtSvt5oOfXfpxr8qChSFLI2WApMRTd20wLshNAIBf5RQjkRArs2V9LnhvhZjUZqOBOp4RE1GW1jpys800TfveFQ8P'
        }
    };
}
else
{ // production
    CONFIG.backend = {
        endpoint: 'https://app.gooddayenglish.com',
        apiPath: '/api',
        storagePath: 'https://app.gooddayenglish.com/data',
        shortCode: 'P'
    };

    CONFIG.notifications = {
        publicServerKey: '???',
        appId: '4490fc3c-1dd7-4ad6-94ea-ddbc7d3760da'
    };

    CONFIG.onesignal = {
        appId: '4490fc3c-1dd7-4ad6-94ea-ddbc7d3760da',
        restApiKey: 'MGQwNjYyZDEtMDM2Ni00NjRkLWExMDAtZGM0Y2U0ZGI2OGIz',
        safariWebId : 'web.onesignal.auto.1947bcbb-3df5-45a5-b464-0be0e15f4a2c'
    };

    CONFIG.captcha = {
        siteKey: '6LdSOacaAAAAALK6AnZ3SKBAF4VL-Q4P6i4xrS6s'
    };

    CONFIG.payments = {
        library: 'stripe',
        stripe:
        {
            publicKey: 'pk_live_51IhhkqGGKEtSvt5ocslubl7TXHK7BxSK9ppLgEzRzNjYtKLvIEm68ZVPcp7UPZWIHzytfaSuZSpV3wajq0HHpwzb00yeH1VJhV'
        }
    };
}


CONFIG.booking = {
    daylimit: 14,
    session: 30,
    onlineHours: 4,
    protectionHours: 12
};

export { CONFIG };
