<script>

export default
{
    name: 'errorDialog',
    props:
    {
        title:   { type: String, default: 'Error' },
        text:    { type: String, default: 'An unknown error occured!' },
        details: { type: String, default: '' },
        action:  { type: String, default: 'Retry' }
    },
    data()
    {
        return {
            showDetails: !this.details
        };
    },
    methods:
    {
        show()
        {
            this.$refs.dialog.show();
        },
        hide()
        {
            this.$refs.dialog.hide();
        },
        onDialogHide()
        {
            this.$emit('hide');
        },
        onAction()
        {
            this.$emit('ok');
            this.hide();
        }
    }
};
</script>

<template>
    <q-dialog ref="dialog" persistent @hide="onDialogHide" transition-show="flip-down" transition-hide="flip-up">
        <q-card square style="width: 576px; max-width: 90vw; box-shadow: 0 0 24px -8px black;">

            <q-toolbar class="q-pt-sm bg-red-9 text-white">
                <q-btn flat round dense icon="warning" />
                <q-toolbar-title>{{title}}</q-toolbar-title>
            </q-toolbar>

            <q-card-section class="bg-red-7 text-white">
                <span class="text-body2">{{text}}</span>
            </q-card-section>

            <q-card-section v-if="showDetails" class="q-pt-none bg-red-7 text-white">
                <small class="text-grey-4">{{details}}</small>
            </q-card-section>

            <q-card-actions align="right" class="bg-red-7">
                <!-- <q-btn v-if="!showDetails" flat class="text-white" label="Details" @click="showDetails = true" /> -->
                <q-btn flat class="text-white" :label="action" @click="onAction" />
            </q-card-actions>

        </q-card>
    </q-dialog>
</template>

