
import feathersClient, {makeServicePlugin, BaseModel} from '../../api/feathers';
class Comment extends BaseModel
{
    static modelName = 'Comment';
    static instanceDefaults()
    {
        return {
            text: ''
        };
    };
}

const servicePath = feathersClient.getServicePath('comments');
const servicePlugin = makeServicePlugin({Model: Comment, service: feathersClient.service(servicePath), servicePath});

// Optionally add service-level hooks, here:
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

  export default servicePlugin;
